
ul > li {
    list-style: disc inside;
}

section#resume div.skill.columns {
    padding: 0px;
    display: grid;
}
    

section#resume div.skills {
    line-height: 150%;
}

section#resume div.skills em {
    font-size: 100%;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    font-style: normal;
}

section#resume div.skills h1,
section#resume div h1,
div.open-source h3 {
    margin-bottom: 15px;
    display: inline;
    margin-right: 0.5em;
    font: 18px/24px "opensans-bold", sans-serif
}

section#resume div.skills>div {
    margin-bottom: 15px;
}

section#resume p,
section#hobbies p  {
    margin: 0 0 10px 0;
    line-height: 150%;
}

header#home p {
    line-height: 20px;
    margin: 0px
}

header#home div.row div.columns {
    padding: 0px;
}

div.columns.grid-wrapper div.as-table div.technology {
    line-height: 150%;
}

div.columns.grid-wrapper div.as-table>div {
    text-overflow: clip;
}


section#resume div.work-position {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

@media screen {
    header#home {
        min-height: 739px;
    }

    header#home span.print {
        display: none;
    }

    header .social span {
        display: none
    }

    .print {
        display: none;
    }

    section#about .print {
        display: none;
    }
    section#about .columns.contact-details {
        padding: 0 0px;
    }

    section#resume div.work div.main-col div.markdownLoader li {
        line-height: 24px;
    }

    section#resume div.work div.main-col div.markdownLoader h3 {
        font-size: 15px;
        line-height: 21px;
    }

    section#resume div.markdownLoader,
    section#hobbies div.markdownLoader,
    section#resume div.grid-wrapper {
        margin-bottom: 30px;
    }

    section#resume div.markdownLoader h3,
    section#hobbies div.markdownLoader h3 {
        margin-top: 30px;
        display: block;
    }

    div.markdownLoader ul, div.markdownLoader ol {
        margin-bottom: 6px;
        margin-top: 6px;
    }
    div.markdownLoader ul li {
        margin-bottom: 10px;
    }
}

@media print {
    body {
        font-size: small;
    }

    div.header-col h1 span {
         font-size: 1em;
    }

    h3 {
        font-size: 1.2em;
    }

    nav#nav-wrap, 
    div.banner span#onScreenIntro, 
    div.banner span.social,
    header .social a,
    header p.scrolldown,
    section#about .screen,
    section#contact,
    section#testimonials,
    footer,
    div.rc-anchor {
        display: none;
    }

    header#home {
        margin-bottom: 12px;
    }
    header#home span.screen {
        display: none;
    }

    header#home .print .contact {
        text-align: center;
    }

    header#home .print .contact ul li {
        list-style: none;
        list-style-type: circle;
        display: inline;
        margin-right: 10px;
    }

    div#root div.App {
        margin: 10px;
    }

    section ul li {
        line-height: 20px;
    }

    section ul {
        margin-top: 4px;
    }

    ul li {
        margin-bottom: 10px;
    }

    section>div.row div.header-col>h1 {
        font-size: 20px;
        border-bottom: #ddd solid 1px;
    }

    header#home h3 {
        width: 50%;
    }

    header#home h1,
    header#home h3 {
        display: inline;
        float: left;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    header#home h1 {
        font-size: 3em;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    header#home h3 {
        font-size: 1em;
    }

    section#about {
        display: none;
    }

    header#home div.contact ul {
        margin-bottom: 0px;
    }

    div.banner-text hr {
        margin-bottom: 10px;
    }

    section#resume .main-col {}

    header ul.social li {
        display: inline;
        margin: 0.5em;
        width: 50%;
    }

    div.grecaptcha-badge {
        display: none;
    }
}

section {
    padding-bottom: 20px;
}

section#resume>div.row {
    /*margin-bottom: 12px;*/
    padding-bottom: 12px;
    /*border-bottom: 1px solid #e8e8e8; */ 
}

section div.grid-wrapper div.as-table em {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    font-style: normal;    
}

section div.grid-wrapper {
    padding: 0px;
    display: contents;
}

section div.as-table {
    font-size: 90%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto; 
    column-gap: 25px;
    row-gap: 10px;
    width: 100%;
}

section div.as-table>div {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

section div.as-table>div p {
    margin: 0px 0px 10px 0px;
}

section#hobbies {
    background: #fff;
    padding-top: 0px;
    padding-bottom: 72px;
    overflow: hidden;
}

section#hobbies div.hobby {
    margin-bottom: 48px;
    padding-bottom: 24px;
    border-bottom: 1px solid #e8e8e8;
}

section#resume h3 {
    font-size: 20px/25px;
    font-family: "opensans-bold", sans-serif;
    display: inline;
    padding-right: 15px;
}

section#resume .info {
    font-size: 16px/24px;
    font-family: "librebaskerville-italic", serif;
    color: #6e7881;
    margin-bottom: 18px;
    margin-top: 9px;
}

section#resume div.work div.main-col>div.row {
    margin-bottom: 20px;
}